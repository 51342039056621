import React from 'react';
import { Layer, Source } from 'react-map-gl';

export default function OpacityOverlay() {
    const Data = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [
                [
                    [180, 90],
                    [-180, 90],
                    [-180, -90],
                    [180, -90],
                    [180, 90],
                ],
                [],
            ],
        },
    };

    const maskLayer = {
        id: 'polygon',
        type: 'fill',
        source: {
            type: 'geojson',
        },
        paint: {
            'fill-color': '#1D1D1D',
            'fill-opacity': 0.25,
        },
    };

    return (
        <Source type="geojson" data={Data}>
            <Layer {...maskLayer} />
        </Source>
    );
}
