import React, { useState, useEffect } from 'react';
import '../App.scss';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import Flickity from 'react-flickity-component';
import { useMediaQueryIsDesktop } from '../helpers/hooks';
import arrowMobile from '../assets/images/arrow.svg';

import SubRotasData from '../assets/SubRotasData.json';
import { Biografia, SpanNavBar } from './TopNavBar';
import BottomNavBar from './BottomNavBar';

function Cell({ rotaObjectImage, showModal }) {
    const { i18n } = useTranslation();
    const [hover, setHover] = useState(false);
    const getRotaImageTitle = (rotaObjectImage) => rotaObjectImage[i18n.language].title;

    return (
        <>
            <div onMouseLeave={() => setHover(false)}
                 onMouseEnter={() => setHover(true)} 
                 className="carousel-cell">
                {/*<p>{getRotaImageTitle(rotaObjectImage)}</p>*/}
                <img className="img_slide" src={rotaObjectImage.src} alt={rotaObjectImage.tag} />
                <div className={hover ? "show" : "hide"}>
                            <div className="btn" onClick={showModal}>
                                <div className="btn-icon">+</div>
                            </div>
                            </div>
            </div>
        </>
    );
}

function Modal({ rotaObjectImage, onClose }) {
    const { i18n } = useTranslation();
    const getRotaImageTitle = (rotaObjectImage) => rotaObjectImage[i18n.language].title;
    const getRotaImageDescription = (rotaObjectImage) => rotaObjectImage[i18n.language].info;
    const isDesktop = useMediaQueryIsDesktop();

    return (
        <div className="modal">
        {isDesktop ?            
        <div className='modal-container'>
                <div>
                    <span className="close" onClick={onClose}>
                        <p className='arrow-rota'><img src={arrowMobile} /></p>
                    </span>
                </div>

                <div className='img-modal'>
                    <img className="modal-content" src={rotaObjectImage.src} alt={getRotaImageTitle(rotaObjectImage)} />
                </div>

                <div className='text-modal'>

                    <div className='texto-md'>
                        <div className="caption">{getRotaImageTitle(rotaObjectImage)}</div>
                        <div className="text">{getRotaImageDescription(rotaObjectImage)}</div>
                    </div>
                </div>
            </div> : <div className='only-mobile-modal'>
            <div className='modal-container'>
                <div>
                    <span className="close" onClick={onClose}>
                        <p className='arrow-rota'><img src={arrowMobile} /></p>
                    </span>
                </div>

                <div className='img-modal'>
                    <img className="modal-content" src={rotaObjectImage.src} alt={getRotaImageTitle(rotaObjectImage)} />
                </div>

                <div className='text-modal'>
                    <div className='texto-md'>
                        <div className="caption">{getRotaImageTitle(rotaObjectImage)}</div>
                        <div className="text">{getRotaImageDescription(rotaObjectImage)}</div>
                </div>
                </div>
                </div>
            </div>

        }
        </div>
    )
}

function Carousel({ rotaObject, showModal }) {
    const cellElements = rotaObject.images.map((rotaObjectImage, index) => (
        <Cell key={index} rotaObjectImage={rotaObjectImage} showModal={() => showModal(rotaObjectImage)} />
    ));

    return (
        <div className="container-flickity">
            <Flickity
                options={{
                    lazyLoad: true,
                    freeScroll: true,
                    cellAlign: 'left',
                    freeScrollFriction: 0.03,
                    prevNextButtons: true,
                    contain: true,
                    groupCells: 2,
                    pageDots: false,
                    reloadOnUpdate: false,
                    draggable: true,
                }}>
                {cellElements}
            </Flickity>
        </div>
    );
}

export default function Slide({ rotaObject, title }) {
    const isDesktop = useMediaQueryIsDesktop();
    const { t, i18n } = useTranslation();
    const [clamped, setClamped] = useState(true);
    const [showMoreText, setShowMoreText] = useState(true);
    const [modalState, setModalState] = useState({ show: false, image: null });

    const handleClick = () => setClamped(!clamped);

    const getRotaDescription = (rotaObject) => rotaObject[i18n.language].description;

    const [displayRotaObject, setDisplayRotaObject] = useState(rotaObject);
    useEffect(() => setDisplayRotaObject(rotaObject), [rotaObject]);

    const subRotas = SubRotasData.filter(srd => srd.parentTag === rotaObject.tag);

    const showModal = (image) => {
        setModalState({ show: true, image });
    };

    return (
        <div className="container-context">
            {modalState.show && (
                <Modal rotaObjectImage={modalState.image}
                    onClose={() => setModalState({ show: false })}
                />
            )}
            
            <div className="description-context">
                <div className='container-essen'>
                <SpanNavBar rotaObject={rotaObject} title={title}/>    
                <div className='title_routes'>
                    <h1 className={`title-context ${displayRotaObject.tag !== rotaObject.tag ? 'unselected' : ''}`}
                        onClick={() => setDisplayRotaObject(rotaObject)} >{rotaObject[i18n.language].title}</h1>

                    <div className='sub-rotas-mobile'>
                    {subRotas.map(subRota =>
                        <h1 className={`title-context ${displayRotaObject.tag !== subRota.tag ? 'unselected' : ''}`}
                            key={subRota.tag}
                            onClick={() => setDisplayRotaObject(subRota)} >{subRota[i18n.language].title}</h1>
                    )}
                    </div>
                </div>

                <div className='content-text'>
                    {isDesktop ? 
                    <>
                    <div className='long-text-container'>
                        <div className="long-text">{getRotaDescription(displayRotaObject)}</div>
                    </div>
                    </> 
                    : 
                    <div className={clamped ? "clamp" : "long-text"}>{getRotaDescription(displayRotaObject)}</div>}
                </div>

                {isDesktop ? null : showMoreText && (
                    <>
                        <button className='button_more' onClick={handleClick}>{t('read')} {clamped ? t('more-info') : t('less')}</button>
                    </>
                )}
                </div>
            </div>

            {isDesktop
                ? <div className="slide-context"><Carousel rotaObject={displayRotaObject} showModal={showModal} /></div>
                : <>
                    {!clamped ? null :
                        <div className="slide-context">
                            <Carousel rotaObject={displayRotaObject} showModal={showModal} />
                        </div>
                    }
                </>
            }
        </div>
    );
}
