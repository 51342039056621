import React from 'react';
import '../../App.scss';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

import arrowMobile from '../../assets/images/arrow.svg';

export default function MarkerMenu({title, markerMaxIndex, markerIndex, prevMarker, nextMarker}) {
    const { t } = useTranslation();

    return (
        <>
           <div className='bottomNavBar-Div'>
                <div className='title-home'>{t('map')}</div>
                <div className='box-bottom'>
                    <div className='mobile-span'>
                        <button className={`bottomNavButton arrow ${markerIndex !== 0 ? '' : 'invisible' }`} onClick={prevMarker}><img src={arrowMobile} /></button>
                        <span className='bottomNavButton active'>{title}</span>
                        <button className={`bottomNavButton arrow ${markerIndex !== markerMaxIndex ? '' : 'invisible' }`} onClick={nextMarker}><img className='controls' src={arrowMobile} /></button>
                    </div>
                </div>
            </div>
        </>    
    );
}
