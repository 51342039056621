import React, { useState } from 'react';
import { ViewStatus } from '../helpers/utils';
import { useMediaQueryIsDesktop } from '../helpers/hooks';
import arrowMobile from '../assets/images/arrow.svg'; 
import { useTranslation, withTranslation, Trans } from 'react-i18next';

export default function BottomNavBar({ setCurrentView }) {
    const [activeId, setActiveId] = useState(ViewStatus.Contexto);

    function TabSelector() {
        const isDesktop = useMediaQueryIsDesktop();

        return (
            <>
                <div className="bottomNavBar-Div">
                    <div className='box-bottom'> 
                    {isDesktop
                        ? <DesktopTab activeId={activeId} selectButton={selectButton}/>
                        : <MobileTab activeId={activeId} selectButton={selectButton}/>}
                </div>
                </div>
            </>
        );
    }

    const selectButton = (viewStatus) => {
        if(viewStatus === null) return;
        setCurrentView(viewStatus);
        setActiveId(viewStatus);
    };

    return (
        <>
            <TabSelector activeId={activeId} setActiveId={setActiveId} />
        </>
    );
}

function MobileTab({selectButton, activeId}) {
    const { t } = useTranslation();

    const buttonOrder = {
        [ViewStatus.Reconstituicao]: {prev: null, next: ViewStatus.Contexto},
        [ViewStatus.Contexto]: {prev: ViewStatus.Reconstituicao, next: ViewStatus.Planta},
        [ViewStatus.Planta]: {prev: ViewStatus.Contexto, next: null},
    };

    return <>
        <div className='mobile-span'>
            <button className='bottomNavButton arrow' style={{visibility: `${buttonOrder[activeId].prev === null ? 'hidden' : ''}`}} onClick={() => selectButton(buttonOrder[activeId].prev)}><img src={arrowMobile} /></button> 
            <span className='bottomNavButton active'>{t(activeId)}</span>
            <button className='bottomNavButton arrow' style={{visibility: `${buttonOrder[activeId].next === null ? 'hidden' : ''}`}} onClick={() => selectButton(buttonOrder[activeId].next)}><img className='controls' src={arrowMobile} /></button> 
        </div>
    </>;
}

function DesktopTab({selectButton, activeId}) {
    const { t } = useTranslation();

    return <>
        <button className={`bottomNavButton ${activeId === ViewStatus.Reconstituicao ? 'active' : ''}`}
                onClick={() => selectButton(ViewStatus.Reconstituicao)}>
            {t(ViewStatus.Reconstituicao)}
        </button>

        <button className={`bottomNavButton ${activeId === ViewStatus.Contexto ? 'active' : ''}`}
                onClick={() => selectButton(ViewStatus.Contexto)}>
            {t(ViewStatus.Contexto)}
        </button>

        <button className={`bottomNavButton ${activeId === ViewStatus.Planta ? 'active' : ''}`}
                onClick={() => selectButton(ViewStatus.Planta)}>
            {t(ViewStatus.Planta)}
        </button>
    </>;
}