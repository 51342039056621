import React, { useEffect, useState } from 'react';
import { useTrail, animated } from 'react-spring';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { TopNavBarIntro } from './TopNavBar';
import { IntroAnimationColor } from './IntroHandler';
import { useMediaQueryIsDesktop } from '../helpers/hooks';


export function Tutorial({ onAnimationEnd }) {
    const { t, i18n } = useTranslation();
    const isDesktop = useMediaQueryIsDesktop();

    const items = t('mertola');
    //const config = { mass: 5, tension: 2000, friction: 200 }
    const [isAction, setIsAction] = useState(true);
    const btnClickHandler = () => {
        setIsAction(onAnimationEnd);
    };

    const textLines = items.split('\n');
    const [reveal, setReveal] = useState(false);

    const trail = useTrail(textLines.length, {
        from: { opacity: 0 },
        to: { opacity: reveal ? 1 : 0 },
        config: { mass: 5, tension: 2000, friction: 200 },
        delay: 500,
    });

    useEffect(() => {
        setTimeout(() => {
            setReveal(true);
        }, 1000);
    }, []);

    //COOKIES
/*     const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        const data = window.localStorage.getItem('COOKIES');
        if (data !== null) { setShowBanner(JSON.parse(data)); }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('COOKIES', JSON.stringify(showBanner));
    }, [showBanner]); */

    return <>
        <TopNavBarIntro />
{/*         {showBanner && (
            <div className='cookies-policy'>
                <CookieConsent
                    debug={true}
                    buttonText={"Got it"}
                    onAccept={() => setShowBanner(false)}>This website uses cookies to ensure you get the best experience on our website. </CookieConsent>
            </div>
        )} */}
        <div className={(isAction ? "pointer-none" : " ")}>
            <IntroAnimationColor />
            
            <div className="trails-main">
                <div className='text-complemento'>
                    <div className='text_01'>
                        <p className='texto-intro-desc'>{t('asideText')}</p>
                    </div>

                    <div className='text_02'>
                        <button className="botao-intro" onClick={btnClickHandler}>{t('enter')}</button>
                        {isDesktop ? <p className="explore-mouse">{t('mouse')}</p> : null}
                    </div>
                </div>
            </div>
        </div>
    </>;
}
