import React from 'react';
import '../App.scss';
import { useTranslation, withTranslation, Trans } from 'react-i18next';


export default function Logos() {
    const { t } = useTranslation();

    return (
    <>
        <div className='logos_box'>
            <p>{t('explore')}</p>
            <div className='logos_home'>
                <ul>
                    <li className='logo_uni'><img src='/assets/logos/campo_mertola_br.png' alt='Logo Campo Arqueológico de Mértola' /></li>
                    <li className='logo_uni'><img src='/assets/logos/camara_mertola_br.png' alt='Logo Câmara de Mértola' /></li>
                    <li className='logo_uni'><img src='/assets/logos/alentejo2020.png' alt='Logo Alentejo 2020' /></li>
                    <li className='logo_uni'><img src='/assets/logos/portugal2020.png' alt='Logo Portugal 2020' /></li>
                    <li className='logo_uni'><img src='/assets/logos/ue2020.png' alt='Logo UE 2020' /></li>
                </ul>
            </div>
        </div>
    </>    
    )
}