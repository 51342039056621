function distanceBetweenPoints(location1, location2, unit = 'K') {
    const { latitude: lat1, longitude: lon1 } = location1;
    const { latitude: lat2, longitude: lon2 } = location2;

    const radLat1 = (Math.PI * lat1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radTheta = (Math.PI * theta) / 180;
    let dist =
        Math.sin(radLat1) * Math.sin(radLat2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);

    dist = Math.min(dist, 1);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    if (unit === 'K') {
        dist *= 1.609344;
    }

    if (unit === 'N') {
        dist *= 0.8684;
    }

    return dist * 1000; // to metres
}

const ViewStatus = {
    Planta: 'floor-plan',
    Contexto: 'historical-context',
    Reconstituicao: 'reconstitution',
};

const isDevelopment = () =>
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export { isDevelopment, distanceBetweenPoints, ViewStatus };
