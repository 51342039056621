import '../App.scss';
import { useParams } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import BottomNavBar from '../components/BottomNavBar';

import React, { useEffect, useRef, useState } from 'react';
import Slide from '../components/Slide';
import Planta from '../components/Plant';
import { ViewStatus } from '../helpers/utils';
import { useTranslation } from 'react-i18next';

import RotasData from '../assets/RotasData.json';
import i18next from 'i18next';

function Panorama3DVista({ url }) {
    const refIframe = useRef(null);

    useEffect(() => {
        if (refIframe.current) {
            refIframe.current.src = url;
            console.log({ url });
        }
    }, [url]);

    return <>
                <iframe ref={refIframe} src={url} title="3D Vista Panorama" />
            </>
}

export default function Rota() {
    const { tag } = useParams();
    const { t } = useTranslation();

    let rotaObject = RotasData.find((rd) => rd.tag === tag);
    //console.dir({rotaObject});

    const [currentView, setCurrentView] = useState(ViewStatus.Contexto);
    console.log('View ' + currentView);

    // const originalUrl = "/assets/3dvista/index.htm#yaw=0&pitch=0&media-name=" + `${rotaObject.panoramaPath}`;
    const originalUrl = `/assets/3dvista/index.htm#media-name=${rotaObject.panoramaPath}`+ `&language=${i18next.language}`;
    const floorplanURL = originalUrl + '&trigger-overlay-name=eye'; // &trigger-overlay-event=click";
    const panoramaUrl =
        currentView === ViewStatus.Planta ? floorplanURL : originalUrl;

    console.log({ panoramaUrl, isPlanta: currentView === ViewStatus.Planta });

    // http://localhost:3000/assets/3dvista/index.htm?yaw=0&pitch=0&media-name=mesquita&trigger-overlay-name=coco

    return (
        <>
            <div>
                <div className="top-gradient visible"></div>
                <TopNavBar title={rotaObject.tag} rotaObject={rotaObject} />
            </div>
            <div>
            <Panorama3DVista panorama url={panoramaUrl} />

                {currentView === ViewStatus.Reconstituicao && (
                <>
                #ARROWS
                </>
                )} 

                {currentView === ViewStatus.Contexto && (
                <>
                    <div className="overlay-context ">
                        <Slide rotaObject={rotaObject} />
                    </div>
                </>
                )}
               {currentView === ViewStatus.Planta && (
                <>
                    <div className="overlay-context">
                        <Planta rotaObject={rotaObject} />
                    </div>
                </>
                )}
            </div>
            <div>
                <div className="bottom-gradient visible"></div>
                <BottomNavBar
                    title={rotaObject.title}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    viewStatus={ViewStatus}
                />
            </div>
        </>
    );
}
