import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import pt from '../assets/locales/pt.json';
import en from '../assets/locales/en.json';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'pt',
        debug: true,
        resources: {
            en,
            pt,
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;