import './App.scss';
import 'sanitize.css';
import React, { useState } from 'react';
import { HashRouter, Routes, Route} from 'react-router-dom';
import Rota from './pages/Rota';
import Home from './pages/Home';
import { ShowMenuContext } from './ShowMenuContext';

export default function App() {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <HashRouter>
            <ShowMenuContext.Provider value={[showMenu, setShowMenu]}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/rota/:tag" element={<Rota />} />
                </Routes>
            </ShowMenuContext.Provider>
        </HashRouter>
    );
}
