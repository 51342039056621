import React, { useState, useMemo } from 'react';
import { Marker, useMap } from 'react-map-gl';
import userMarkerImage from '../../assets/images/pulse.gif';
import { isDevelopment } from '../../helpers/utils';

export default function UserLocationMarker({ onLocationChange }) {
    const { current: mapRef } = useMap();

    const [userLatLng, setUserLatLng] = useState(null);
    const [locationAsked, setLocationAsked] = useState(false);

    const locateUser = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            if (isDevelopment()) {
                latitude = 37.6398671;
                longitude = -7.6632135;
            }

            setUserLatLng({ latitude, longitude });
            mapRef.getMap().triggerRepaint();

            if (onLocationChange) {
                onLocationChange({ latitude, longitude });
            }
        });
    };

    const userLocationMarker = useMemo(() => {
        let markerWidth = 40;
        let markerHeight = 40;

        return userLatLng ? (
            <Marker
                latitude={userLatLng.latitude}
                longitude={userLatLng.longitude}
            >
                <img
                    style={{ display: 'block' }}
                    className="siteMarkerImage"
                    height={markerHeight}
                    width={markerWidth}
                    src={userMarkerImage}
                    alt={'user location'}
                />
            </Marker>
        ) : null;
    }, [userLatLng]);

    if (!locationAsked) {
        setLocationAsked(true);
        locateUser();
    }

    return <>{userLocationMarker}</>;
}
