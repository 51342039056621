import React from 'react';
import { useTranslation } from 'react-i18next';

import '../App.scss';

import Logos from './Logos';
import { useMediaQueryIsDesktop } from '../helpers/hooks';

export default function Aside() {
    const isDesktop = useMediaQueryIsDesktop();
    const { t } = useTranslation();

    return (
        <>
            { isDesktop &&
            <>
                {/*                
                <div className='elements_home'>
                    <p>{t('asideText')}</p>
                </div>*/}
                <Logos /> 
            </>
            }
        </>    
    );
}
