import React, { useRef, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Link } from 'react-router-dom';
import Map, { MapProvider, Marker, Source, Popup } from 'react-map-gl';

import Loading from '../map/Loading';
import MarkerMenu from './MarkerMenu';
import UserLocationMarker from './UserLocationMarker';
import OpacityOverlay from './OpacityOverlay';

import { ShowMenuContext } from '../../ShowMenuContext';
import { useCheckMobileScreen } from '../../helpers/hooks';
import { distanceBetweenPoints } from '../../helpers/utils';
import RotasData from '../../assets/RotasData.json';
import closestMarkerImage from '../../assets/images/icon_mapa.svg';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

// https://github.com/visgl/react-map-gl/issues/1266
import mapboxgl from 'mapbox-gl';
// This is a dependency of react-map-gl even if you didn't explicitly install it
mapboxgl.workerClass =
    // eslint-disable-next-line no-undef
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TOKEN =
    'pk.eyJ1Ijoic3RlZmZhbmRhdmllcyIsImEiOiJjamsxOHJjNGgwZHZ2M3FrNmk4eWR0YXBoIn0.s9iCknoFBpMoId9COtLdqw';

export default function ReactMap() {
    const { t, i18n } = useTranslation();
    const [showLoading, setShowLoading] = useState(true);
    const [showMenu, _setShowMenu] = useContext(ShowMenuContext);
    const [closestMarkerTag, setClosestMarkerTag] = useState();
    const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(0);
    const isMobile = useCheckMobileScreen();
    const mapRef = useRef();
    const [popupInfo, setPopupInfo] = useState(null);

    // Initial view state
    const initialViewState = {
        longitude: -7.6641679,
        latitude: 37.6385712,
        zoom: 15.6,
        bearing: 0,
        pitch: 0,
    };

    const [viewState, setViewState] = useState(initialViewState);

    // Map pan limits
    const bounds = [
        [-7.681010, 37.631431], // Southwest coordinates
        [-7.648935, 37.648179], // Northeast coordinates
    ];

    const selectMarker = (rotaData) => {
        setPopupInfo({
            longitude: rotaData.longitude,
            latitude: rotaData.latitude,
            name: rotaData[i18n.language].title,
            tag: rotaData.tag,
            rotaData,
        });
    }; 



    const markers = useMemo(() => {
        //let markerImage = spotPoint;
        //let markerSelectedImage = 'http://simpleicon.com/wp-content/uploads/map-marker-5.png';
        let markerWidth = 20;
        let markerHeight = 20;

        const getMarkerImageSrc = (marker, markerIndex) =>
            isMobile && markerIndex === selectedMarkerIndex
                ? closestMarkerImage
                : marker.markerImg;

        return RotasData.map((marker, markerIndex) => (
            <Marker
                className="mapMarker"
                key={marker.tag}
                latitude={marker.latitude}
                longitude={marker.longitude}
                offset={[0, 0]}
                rotation={0}
            >
                <Link to={`/rota/${marker.tag}`}>
                    <img
                        style={{ display: 'block' }}
                        className="siteMarkerImage"
                        src={getMarkerImageSrc(marker, markerIndex)}
                        height={markerHeight}
                        width={markerWidth}
                        onMouseOver={() => selectMarker(marker)}
                        alt={marker.tag}
                    />
                </Link>
            </Marker>
        ));
    }, [closestMarkerTag, isMobile, selectedMarkerIndex]);

    const onLocationChange = (location) => {
        let closestMarker,
            closestDistance = Number.MAX_VALUE;

        for (const marker of RotasData) {
            let distance = distanceBetweenPoints(location, marker);

            if (distance < closestDistance) {
                closestMarker = marker;
                closestDistance = distance;
            }
        }

        setClosestMarkerTag(closestMarker.tag);
    };

    const onLoadMap = () => {
        setShowLoading(false);
    };

    const onMove = evt => {
        setViewState(viewState => ({
            ...evt.viewState,
            bearing: viewState.bearing,
            // latitude: viewState.latitude,
            // longitude: Math.cos(viewState.bearing * (Math.PI / 180)) * evt.viewState.longitude,
            // bearing: Math.min(-30, Math.max(-30, evt.viewState.bearing))
        }));
    };

    useEffect(() => {
        if (isMobile) {
            selectMarker(RotasData[selectedMarkerIndex]);

            const {latitude, longitude} = RotasData[selectedMarkerIndex];
            setViewState(prev => ({...prev, longitude, latitude}));
        }
    }, [isMobile, selectedMarkerIndex]);

    return (
        <>
        {isMobile ? <MarkerMenu title={RotasData[selectedMarkerIndex].title}
                                  markerMaxIndex={RotasData.length - 1}
                                  markerIndex={selectedMarkerIndex}
                                  nextMarker={() => setSelectedMarkerIndex((selectedMarkerIndex + 1) % RotasData.length)}
                                  prevMarker={() => setSelectedMarkerIndex((selectedMarkerIndex - 1 + RotasData.length) % RotasData.length)} /> : null }
        <div className='mapa-contain'> 
            <MapProvider>
                <Loading showLoading={showLoading} />
                <Map
                    {...viewState}
                    ref={mapRef}
                    maxBounds={bounds}
                    style={{ width: '100vw', height: '100vh' }}
                    mapStyle="mapbox://styles/mapbox/satellite-v9"
                    mapboxAccessToken={TOKEN}
                    attributionControl={false}
                    logoControl={false}
                    dragPan={true}
                    dragRotate={true}
                    onMove={onMove}
                    maxZoom={16}
                    minZoom={15.6}
                    doubleClickZoom={false}
                    scrollZoom={false}
                    maxPitch={30}
                    minPitch={0}
                    terrain={{ source: 'mapbox-dem', exaggeration: 1.2 }}
                    onLoad={onLoadMap}
                >
                    <OpacityOverlay />

                    <Source
                        id="mapbox-dem"
                        type="raster-dem"
                        url="mapbox://mapbox.mapbox-terrain-dem-v1"
                    />

                    {markers}

                    {isMobile && (
                        <UserLocationMarker
                            onLocationChange={onLocationChange}
                        />
                    )}

                    {popupInfo && !showMenu && (
                        <Popup
                            longitude={Number(popupInfo.longitude)}
                            latitude={Number(popupInfo.latitude)}
                            onClose={() => setPopupInfo(null)}
                            closeOnMove={true}
                        >
                            <button>
                                <Link to={`/rota/${popupInfo.tag}`}>
                                <h3 className="pop-up-title">
                                    {popupInfo.name}
                                    <p className="sub-pop-title">{t('more')}</p>
                                </h3>
                                </Link>
                            </button>
                        </Popup>
                    )}
                </Map>
            </MapProvider>
        </div>
    </>
    );
}
