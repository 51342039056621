import React from 'react';

import '../App.scss';

import Aside from '../components/Aside';
import TopNavBar from '../components/TopNavBar';
import ReactMap from '../components/map/ReactMap';
import IntroHandler from '../components/IntroHandler';

export default function Home() {
    return (
        <IntroHandler>
            <div className="main noselect">
                <div className='top-gradient visible'></div>
                <TopNavBar />
                <ReactMap />
                <Aside />
                <div className="bottom-gradient visible"></div>
            </div>
        </IntroHandler>
    );
}
