import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { render } from 'react-dom';
import { withTranslation, Trans } from 'react-i18next';

import '../App.scss';
import VideoIntro from '../assets/MAP_ZOOM.mp4';
import { useSessionStorage, useEventListener } from '../helpers/hooks';
import { Tutorial } from './Tutorial';


export default function IntroHandler(props) {
    const [animationShown, setAnimationShown] = useSessionStorage("introAnimationShown", false);
    const handleUnload = () => window.sessionStorage.clear();
    useEventListener("beforeunload", handleUnload);

    const onAnimationEnd = () => setAnimationShown(true)

    return <>
        {!animationShown ? <Tutorial onAnimationEnd={onAnimationEnd} /> : props.children}
    </>;
}

export const IntroAnimationColor = () => {
    const [isVisible, setIsVisible] = useState(false);
    const springProps = useSpring({
      from: { transform: 'scale(0)', opacity: 0.5 },
      to: { transform: isVisible ? 'scale(1)' : 'scale(0)', opacity: isVisible ? 1 : 0.5 },
    });

    const videoRef = useRef(null); 

    useEffect(() => {
        setTimeout(() => setIsVisible(true), 100);
      }, []);

      return (
        <animated.div style={{...springProps, width:'100%', height:'100%', position:'absolute', backgroundColor:'#000'}}>
           <div className='opc'>
           <video
            ref={videoRef}
            style={{ width: '100%', height: '100%' }}
            autoPlay
            playsInline
            controls={false}
            muted="muted"
            onCanPlay={() => videoRef.current.play()}
          >
            <source src={VideoIntro} type="video/mp4" />
          </video>
                </div>
        </animated.div>
      );
    };


