import React from 'react';
import { useState, useEffect } from 'react';
import arrowMobile from '../assets/images/arrow.svg';

import '../App.scss';
import '../components/plantas/plantas.scss';

import PlantasData from '../assets/PlantasData.json';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

export default function Plant({ rotaObject, plantaObj, circleObj}) {
    
    const { t, i18n } = useTranslation();
    const plantaRotas = PlantasData.find(srd => srd.parentTag === rotaObject.tag);

    const [displayText, setDisplayText] = useState(plantaObj);

    useEffect(() => setDisplayText(plantaObj), [plantaObj]);

    const [modalState, setModalState] = useState(false);
    const [viewState, setViewState] = useState(false);
    
    const showModal = (circleObj) => {
        setModalState({ 
            title: circleObj[i18n.language].title,
            corte: circleObj.corte,
            circleObj 
        });
    };

    const showImg = (circleObj) => {
        setViewState({ 
            img: circleObj.img,
            circleObj 
        });
    };

    return (
        <div className="container-context-plant">

            <div className='description-plant'>

                <h1 className='title-context'>{rotaObject[i18n.language].title}</h1>
            </div>


            <div className='divide-plant'>
                <div className='lista-pontos'>
                    <ul>
                    {plantaRotas.path.map((plantaObj, id) => 
                        <li
                        key={id}>
                        <p
                        onMouseOver={() => setDisplayText(plantaObj)} 
                        onMouseLeave={() => setDisplayText(null)}
                        className={`point-plant ${displayText === plantaObj ? 'active' : ''}`}>{plantaObj[i18n.language].title2}
                        </p>
                        </li> 
                        )}
                    </ul>
                </div>

                <div className='planta_img'>

                <svg className='planta_svg' viewBox="0 0 970.3964 826.7059">
                {plantaRotas.path.map((plantaObj, id ) => 
                    <path 
                    key={id} 
                    id={plantaObj.id}
                    d={plantaObj.d}
                    className={ displayText === plantaObj ? 'active' : ''} 
                     />
                    )}

                    {plantaRotas.circle.map((circleObj, index) => 
                    <circle 
                    key={index} 
                    cx={circleObj.cx} 
                    cy={circleObj.cy} 
                    r={circleObj.r}
                    className={circleObj.class}
                    onClick={() => showModal(circleObj)}
                    onMouseOver={() => showImg(circleObj)}
                    onMouseLeave={() => showImg(false)}
                     />
                    )}
                </svg>   
                <div className="position-img">
                    {viewState ? (
                        <img className='img-hover' src={viewState.img}/>
                        ) : null}
                </div>           
            </div>

            <div className='spacer-cx'></div>
            </div>
            {modalState ?         
                    (<div className="modal">
                    <div className='modal-container'>

                    <div>
                        <span className="close" onClick={() => setModalState(false)}>
                            <p className='arrow-rota'><img src={arrowMobile} /></p>
                        </span>
                    </div>
                    
                        <div className='img-modal'>
                            <img className="modal-content" src={modalState.corte} />
                        </div>
        
                        <div className='text-modal'>
                        <div className='text-md'>
                            <div className="caption">{modalState.title}</div>                        
                        </div>
                        </div>
                    </div>
                </div>
                    ) 
                     : null}
        </div>
    );
}
