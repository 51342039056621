import React, { useState, useContext, useEffect } from 'react';
import '../App.scss';
import designLogo from '../assets/images/logo_white.svg';
import { Link } from 'react-router-dom';
import Flickity from 'react-flickity-component';
import { ShowMenuContext } from '../ShowMenuContext';
import RotasData from '../assets/RotasData.json';
import Logos from './Logos';
import arrowMobile from '../assets/images/arrow.svg';
import arrow from '../assets/images/arrow2.svg';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { useMediaQueryIsDesktop } from '../helpers/hooks';

function Cell({ rotaObject }) {
    const [showMenu, setShowMenu] = useContext(ShowMenuContext);

    useEffect(() => {
        if (!showMenu[rotaObject]) {
          // Fetch the images here and save them in state
          setShowMenu(true);
        }
      }, [rotaObject, showMenu]);


    // const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => setShowMenu(!showMenu);
    const [hover, setHover] = useState(false);
    const { t, i18n } = useTranslation();
    const isDesktop = useMediaQueryIsDesktop();

    return (
        <>
            {
                isDesktop ?
                <div className="carousel-cell menu"
                    onMouseLeave={() => setHover(false)}
                    onMouseEnter={() => setHover(true)}>
                            <p className='titleCarouselMenu'>{rotaObject[i18n.language].title}</p>
                            <img className='img_menu' src={rotaObject.imageMenu} />

                        <Link to={`/rota/${rotaObject.tag}`} onClick={toggleMenu}>    
                            <div className={hover ? "show" : "hide"}>
                            <div className="btn">
                                <div className="btn-icon">+</div>
                            </div>
                            </div>
                        </Link>
                </div>
            :  <div className="carousel-cell menu">
                    <Link to={`/rota/${rotaObject.tag}`} onClick={toggleMenu}>    
                        <p className='titleCarouselMenu'>{rotaObject[i18n.language].title}</p>
                        <img className='img_menu' src={rotaObject.imageMenu} />
                    </Link>
                </div>}
        </>
    );
}

function CarouselMenu() {
    const isDesktop = useMediaQueryIsDesktop();

    const cellMenuElements = RotasData.map((rotaObject, index) => (
        <Cell key={index} rotaObject={rotaObject} />
    ));

    return (
        <>
            {
                isDesktop ?
                    <Flickity
                        options={{
                            freeScroll: true,
                            cellAlign: "center",
                            freeScrollFriction: 0.03,
                            prevNextButtons: false,
                            contain: true,
                            pageDots: false,
                            reloadOnUpdate: true,
                            draggable: true,
                        }}>
                        {cellMenuElements}

                    </Flickity> : 
                    <>
                    <div className='menu-mobile'>
                        {cellMenuElements}
                        <Logos />
                    </div>
                    </>
            }
        </>
    );
}


export function Biografia({rotaObject}) {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    }
    //const handleClose = () => setIsOpen(false);
    const isDesktop = useMediaQueryIsDesktop();

    const handlePopupClose = () => {
        setSelectedTitleId(null);
        setIsOpen(false);
      }

    const [selectedTitleId, setSelectedTitleId] = useState(null);
    
    return (
        <>
            {isDesktop ?
                <div className='bio-button'>
                    <div id="bio-rotate" onClick={handleOpen} onClose={handlePopupClose}>
                        <p className='input'>{t('bibliography')}</p>
                    </div>
                </div> : null
            }
            <>
                {isOpen && (
                    <>
                        <div className="modal_bio">
                            <button
                                className="button-close-bio"
                                onClick={handlePopupClose}
                            >
                                X
                            </button>
                            <div className="bio-cell-title">{t('bibliography')}</div>
                            <div className="nav-bio">
                            {RotasData.map(title => (
                            <p key={title.id} className={title.id === selectedTitleId ? 'highlight' : ''} onClick={() => setSelectedTitleId(title.id)}>
                                {title[i18n.language].title}
                            </p>
                            ))}
                            </div>
                            <div className='bio-des-content'>
                            {selectedTitleId ? (
                            <p>{RotasData.find(title => title.id === selectedTitleId).bibliography}</p>
                            ) : null }
                        </div>
                        </div>
                    </>
                )}
            </>
        </>
    );
}

export function Menu({ rotaObject, showMenu }) {
    return (
        <>
            <div className="overlay-context-fix">
                <div className="fix-menu">
                    <div className="container-slide scroll">
                    <div className="contain-menu">
                        <CarouselMenu rotaObject={rotaObject} />
                    </div>    
                    </div>
                </div>
            </div>
        </>
    );
        
}

export function SpanNavBar({rotaObject, title}){ 
    function getNextTag(rotaObject) { 
        if (rotaObject) { 
            let index = RotasData.findIndex(rd => rd.tag === rotaObject.tag); 
            return RotasData[(index - 1 + RotasData.length) % RotasData.length].tag; 
        } 
    }
     
    function getPreviousTag(rotaObject) { 
        if (rotaObject) { 
            let index = RotasData.findIndex(rd => rd.tag === rotaObject.tag); 
            return RotasData[(index + 1 + RotasData.length) % RotasData.length].tag; //
        } 
    } 
     
    return( 
        <div className='rotas_button'> 
            <Link to={`/rota/${getNextTag(rotaObject)}`}><p className='arrow-rota'><img src={arrowMobile} /></p></Link> 
            <Link to={`/rota/${getPreviousTag(rotaObject)}`}><p className='arrow-rota'><img className='controls' src={arrowMobile} /></p></Link> 
        </div> 
    ) 
 
} 

function ToggleLanguageMenu() {
    const { i18n } = useTranslation();
    const [isShown, setIsShown] = useState(false);

    const toggleLanguage = () => {
        i18n.changeLanguage(i18n.language === 'pt' ? 'en' : 'pt');
        setIsShown(!isShown);
    };

    return <>
        <button className="titleNavBar flex-button">
            <div className='languages'>
                <p>{i18n.language.toUpperCase()}</p>
            </div>

            {isShown && (
                <div className='lang_open' onClick={toggleLanguage}>
                    {i18n.language === 'pt' ? 'EN' : 'PT'}
                </div>
            )}

            <img className={`arrow_2 ${isShown ? 'arrow_active' : ''}`}
                src={arrow}
                onClick={() => setIsShown(!isShown)} />
        </button>
    </>;
}

export function TopNavBarIntro({ title, marker, rotaObject }) {
    const { t } = useTranslation();
    const isDesktop = useMediaQueryIsDesktop();

    const [showMenu, setShowMenu] = useContext(ShowMenuContext);
    //const toggleMenu = () => setShowMenu(!showMenu);

    return (
        <>
            <div className="topNavBar-Div">
                <Link className="topNavBar-Div--logo" to="/" onClick={() => setShowMenu(false)}>
                    <img src={designLogo} alt={'Logo'} />
                </Link>

                {isDesktop ?<span className="spanNavBar espec"> 
                        {t('mertola')} 
                </span> : null }

                <ToggleLanguageMenu />
            </div>
        </>
    );
}

export default function TopNavBar({ title, marker, rotaObject }) {
    const { t } = useTranslation();
    const isDesktop = useMediaQueryIsDesktop();

    const [showMenu, setShowMenu] = useContext(ShowMenuContext);
    const toggleMenu = () => setShowMenu(!showMenu);

    return (
        <>
            <div className="topNavBar-Div">
                <Link className="topNavBar-Div--logo" to="/" onClick={() => setShowMenu(false)}>
                    <img src={designLogo} alt={'Logo'} />
                </Link>

                {showMenu &&  
                    <span className="spanNavBar"> 
                        {showMenu ? t('pontos') : null} 
                    </span> 
                } 

                <button className="titleNavBar" onClick={toggleMenu}>
                    {showMenu ? t('quit') : <div className='titleNavBar back_border'>{t('menu')}</div>}
                </button>

                {showMenu ? null : <ToggleLanguageMenu />}
                {showMenu ? <Menu rotaObject={showMenu}/> : <Biografia />}
            </div>
            {showMenu && <div className="bottomNavBar-Div-simple"></div>}
        </>
    );
}
